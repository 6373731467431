<template>
  <v-col wrap class="props-container">
    <v-row wrap no-gutters>
      <v-col :cols="6" v-for="(value, feature) in allFeatures" class="margin-bottom-10" v-bind:key="feature" v-show="$vuetify.breakpoint.smAndUp || ($vuetify.breakpoint.mobile && value)">
        <v-layout row class="no-gutters" align-center v-bind:class="{ inactive: !value }">

          <v-flex class="font-20">
            <v-icon class="margin-sides-10 icon-lg" v-if="value">mdi-check-circle-outline</v-icon>
            <v-icon class="margin-sides-10 icon-lg" v-else>mdi-minus-circle-outline</v-icon>
            <span class="font-300">{{$t(`estates.props.${feature}`)}}</span>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { features } from '@/app/modules/estates/utils/estate-props'

export default {

  name: 'EstateFeaturesSection',

  props: {
    features: String
  },

  data () {
    return {
      allFeatures: features
    }
  },

  created () {
    this.features.split(',').forEach((f) => {
      if (this.allFeatures.hasOwnProperty(f)) this.allFeatures[f] = true
    })
  }
}
</script>
