<template>
  <v-row no-gutters class="mobile-contact" justify="center" align=center>
    <v-col>
      <v-bottom-sheet v-model="sheet">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain icon>
            <v-icon  v-bind="attrs" v-on="on" color="bright" class="icon-3xl">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-sheet>
          <div class="margin-tb-35">
            <div class="margin-25">
              <contact-dialog :estate="estate" :activator-text="this.$t('estates.request_showing')" @success="sheet = false"/>
            </div>

            <div class="media-contact margin-25">
              <h5 class="font-20 font-600 margin-tb-5">{{ $t('estates.share') }}</h5>

              <v-row class="links" no-gutters>
                <share-network network="facebook"
                              :url="getFullUrl()"
                              :title="estate.title"
                              :description="estate.description"
                              @open="trackShare('facebook')">
                  <v-icon class="icon-3xl social-icon facebook-icon">mdi-facebook</v-icon>
                </share-network>

                <share-network network="whatsapp"
                              :url="getFullUrl()"
                              :title="estate.title"
                              :description="estate.description"
                              @open="trackShare('whatsapp')">
                  <v-icon class="icon-3xl social-icon whatsapp-icon">mdi-whatsapp</v-icon>
                </share-network>

                <copy-text :text="estateUrl()" :tooltip="$t('estates.copy_link')"/>
              </v-row>
              <spacer height="25px"/>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-col>

    <v-divider vertical dark/>
    <v-col>
      <a :href="`tel:${getPhone()}`" @click="trackContact('phone')" v-if="getPhone()">
        <v-icon class="icon-3xl" color="bright" >mdi-phone-outline</v-icon>
      </a>
    </v-col>

    <v-divider vertical dark/>
    <v-col>
      <a :href="$config.whatsappPhone | whatsapp(`${message}`)" target="_blank" @click="trackContact('whatsapp')">
        <v-icon class="icon-3xl" color="bright" >mdi-whatsapp</v-icon>
      </a>
    </v-col>

    <v-divider vertical dark/>
    <v-col>
      <router-link :to="{name: 'estates', query: query()} " target="_blank" @click.native="trackClick('mobile_bottom_sheet', { action: 'search', slug: estate.slug })">
        <v-icon class="icon-3xl" color="bright" >mdi-magnify</v-icon>
      </router-link>
    </v-col>

    <v-divider vertical dark/>
    <v-col>
      <router-link :to="{name: 'home'}" target="_blank" @click.native="trackClick('mobile_bottom_sheet', { action: 'home', slug: estate.slug })">
        <v-icon class="icon-3xl" color="bright" >mdi-home-outline</v-icon>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import CopyText from '@/common/components/copy-text'
import ContactDialog from './estate-contact-request-dialog'

export default {

  name: 'EstateMobileContact',

  components: {
    ContactDialog,
    CopyText
  },

  props: {
    estate: {
      type: Object,
      required: true
    },

    message: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      sheet: false,
      user: null
    }
  },

  watch: {
    sheet (val) {
      if (val) this.trackClick('mobile_bottom_sheet', { action: 'menu', slug: this.estate.slug })
    }
  },

  methods: {
    query () {
      return {
        free_text: this.estate.address.city ? this.estate.address.city : this.estate.address.general_address,
        deal_type: this.estate.dealType,
        size: [Math.round(this.estate.specifications.size * 0.85), Math.round(this.estate.specifications.size * 1.15)].toString(),
        rooms: this.estate.specifications.rooms,
        price: this.estate.price
      }
    },

    getFullUrl () {
      return window.location.href
    },

    estateUrl () {
      return `https://${window.location.host}/estates/${this.estate.slug}`
    },

    getPhone () {
      const phone = this.user.phones.find((p) => p.name === 'phone')
      if (phone && phone.number) {
        return phone.number
      }

      const mobile = this.user.phones.find((p) => p.name === 'mobile')
      if (mobile && mobile.number) {
        return mobile.number
      }

      return null
    }
  },

  created () {
    this.user = this.estate.contact
  }
}
</script>

<style lang="scss" scoped>
  .mobile-contact {
    position: sticky;
    bottom: 0;
    padding: 10px 5px;
    margin: 0 -12px -12px;
    text-align: center;
    background-color: var(--v-secondary-lighten1);
    z-index: 9;

    .v-btn {
      max-height: 25px;
    }
  }

  .media-contact {

    .copy-text {
      padding-top: 4px;
    }
  }

  .media-contact .links > *,
  .contact-dialog-container{
    margin-left: 10px;
  }
</style>
