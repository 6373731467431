<template>
  <div class="side-box" v-if="user">
    <template v-if="estate.status === 'sold'">
      <h6 class="font-18 margin-bottom-5">{{ $t("estates.contact_sold_title") }}</h6>
      <quick-contact page="estate">
        <template slot="hidden-fields">
          <input type="hidden" name="from_sold_estate" :value="estate.slug">
        </template>
      </quick-contact>
    </template>

    <template v-else>
      <div class="more-contact">
        <h5 class="font-20 font-600">{{ $t('estates.contact_info') }}</h5>

        <v-row wrap no-gutters>
          <v-col cols=4 md=12>
            <!-- Name -->
            <div class="contact-detail">
              <v-icon class="icon-lg">mdi-account</v-icon>
              <span class="font-18 ellipsis">{{fullName()}}</span>
            </div>
          </v-col>
          <v-col cols=4 md=12>
            <!-- Phone -->
            <div class="contact-detail" v-if="phone">
              <a :href="`tel:${phone}`" @click="trackContact('phone')">
                <v-icon class="icon-lg">mdi-phone-outline</v-icon>
                <span class="font-18 ellipsis">{{phone}}</span>
              </a>
            </div>
          </v-col>
          <v-col cols=4 md=12>
            <!-- Mobile -->
            <div class="contact-detail" v-if="mobile">
              <a :href="`tel:${mobile}`" @click="trackContact('mobile')">
                <v-icon class="icon-lg">mdi-cellphone-iphone</v-icon>
                <span class="font-18 ellipsis">{{mobile}}</span>
              </a>
            </div>
          </v-col>
          <v-col cols=4 md=12>
            <!-- email -->
            <div class="contact-detail">
              <a :href="`mailto:${user.email}`" @click="trackContact('email')">
                <v-icon class="icon-lg">mdi-email-outline</v-icon>
                <span class="font-18 ellipsis" dir="ltr">{{user.email}}</span>
              </a>
            </div>
          </v-col>
          <v-col cols=4 md=12>
            <!-- Whatsapp -->
            <div class="contact-detail" v-if="mobile && mobile">
              <a :href="mobile | whatsapp($t('estates.whatsapp_message', { message: estate.address.general_address }))" target="_blank" @click="trackContact('whatsapp')">
                <v-icon class="icon-lg">mdi-whatsapp</v-icon>
                <span class="font-18 ellipsis">{{$t('contact.whatsapp')}}</span>
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <contact-dialog :estate="estate" :activator-text="this.$t('estates.request_showing')"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12>
            <!-- Share -->
            <div class="media-contact">
              <h5 class="font-18 font-600">{{ $t('estates.share') }}</h5>
              <v-row class="links" row no-gutters>
                <share-network network="facebook"
                              :url="getFullUrl()"
                              :title="estate.title"
                              :description="estate.description"
                              @open="trackShare('facebook')">
                  <v-icon class="icon-xl social-icon facebook-icon">mdi-facebook</v-icon>
                </share-network>
                <share-network network="whatsapp"
                              :url="getFullUrl()"
                              :title="estate.title"
                              :description="estate.description"
                              @open="trackShare('whatsapp')">
                  <v-icon class="icon-xl social-icon whatsapp-icon">mdi-whatsapp</v-icon>
                </share-network>
                <copy-text :text="estateUrl()" :tooltip="$t('estates.copy_link')"/>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
   </div>
</template>

<script type="text/javascript">
import ContactDialog from './estate-contact-request-dialog'
import CopyText from '@/common/components/copy-text'
import QuickContact from '@/common/components/quick-contact'

export default {
  name: 'EstatePageSidePane',

  props: {
    estate: {
      type: Object,
      required: true
    }
  },

  components: {
    ContactDialog,
    CopyText,
    QuickContact
  },

  data () {
    return {
      user: null,
      phone: null,
      mobile: null
    }
  },

  methods: {
    fullName () {
      return `${this.user.first_name} ${this.user.last_name}`
    },

    getFullUrl () {
      return window.location.href
    },

    getMobile () {
      const obj = this.user.phones.find((p) => p.name === 'mobile')
      if (obj && obj.number) {
        return obj.number
      }
      return ''
    },

    getPhone () {
      const obj = this.user.phones.find((p) => p.name === 'phone')
      if (obj && obj.number) {
        return obj.number
      }
      return ''
    },

    estateUrl () {
      return `${window.location.protocol}//${window.location.host}/estates/${this.estate.slug}?utm_source=copy`
    }
  },

  async created () {
    this.user = this.estate.contact
    this.phone = this.getPhone()
    this.mobile = this.getMobile()
  }
}
</script>

<style lang="scss" scoped>

  .side-box {
    position: sticky;
    top: 30px;

    .more-contact {
      margin-top: 30px;
      padding: 18px 14px 17px;
      border: 1px solid $divider_light;
      background-color: #f9f7f6;
    }

    h5 {
      margin-bottom: 15px;
    }

    .contact-detail {
      padding: 3px 0;

      span {
        padding: 0 10px;

        &[dir=ltr] {
          justify-self: baseline;
        }
      }
    }

    .media-contact {

      .links > * {
        margin-left: 10px;
      }
    }

    .contact-detail {

      a {
        grid-template-columns: auto 1fr;
        display: grid;
        align-items: flex-end;
        color: inherit;
      }
    }
  }
</style>
