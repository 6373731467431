<template>
  <div :class="['estate-page', {'no-gallery': false/*estate.status === 'sold'*/}]" v-if="estate">

    <EstateGallery :images="images()" :video="video" :location="location" :address="fullAddress"/>

    <v-container :class="{'no-padding': $vuetify.breakpoint.lgAndUp}">
      <v-row class="estate-main" no-gutters>
        <v-col cols=12 md="9">
          <MainDetails :estate="estate"/>

          <EstateDetailsHeader :estate="estate"/>

          <!-- specifications -->
          <v-divider class="margin-tb-35"/>
          <EstateSection type="specifications" :value="estate.specifications" :caption="$t('estates.specifications')"/>

          <!-- features -->
          <v-divider class="margin-tb-35"/>
          <EstateSection type="features" :value="estate.features" :caption="$t('estates.features')"/>

          <!-- contact -->
          <template v-if="isSold && $vuetify.breakpoint.xsOnly">
            <v-divider class="margin-tb-35"/>
            <h6 class="font-18">{{ $t("estates.contact_sold_title") }}</h6>
            <quick-contact page="estate">
              <template slot="hidden-fields">
                <input type="hidden" name="from_sold_estate" :value="estate.slug">
              </template>
            </quick-contact>
          </template>

          <template v-if="!isSold">
            <!-- description -->
            <div v-if="estate.description && estate.about">
              <v-divider class="margin-tb-35"/>
              <EstateSection type="text" :value="estate.description" :caption="$t('estates.description')"/>
            </div>

            <!-- about owner -->
            <div v-if="estate.owner_about">
              <v-divider class="margin-tb-35"/>
              <EstateSection type="text" :value="estate.owner_about" :caption="$t('estates.owner_about')"/>
            </div>
          </template>

          <!-- location -->
          <v-divider class="margin-tb-35"/>
          <EstateSection type="map" :caption="$t('estates.location')" :value="fullAddress"/>

          <!-- virtual tour -->
          <div v-if="vTour">
            <v-divider class="margin-tb-35"/>
            <EstateSection type="iframe" :caption="$t('estates.virtual_tour')" :value="vTour" :attrs="{id: 'frame360'}"/>
          </div>

          <!-- floor plan -->
          <div v-if="floorPlan">
            <v-divider class="margin-tb-35"/>
            <EstateSection type="image" :caption="$t('estates.floor_plan')" :value="floorPlan"/>
          </div>

          <!-- docs -->
          <estate-docs :estate="estate"/>

          <!-- video -->
          <div v-if="video">
            <v-divider class="margin-tb-35"/>
            <EstateSection type="video" :caption="$t('estates.video')" :value="video"/>
          </div>
        </v-col>

        <Spacer width="30px" v-if="$vuetify.breakpoint.mdAndUp"/>

        <v-col v-if="$vuetify.breakpoint.smAndUp">
          <EstatePageSidePane :estate="estate"/>
        </v-col>
      </v-row>

      <!-- latest deals -->
      <v-container v-if="latestDeals.length">
        <v-divider class="margin-tb-35"/>
        <h4 class="font-18 font-600 margin-bottom-10">{{ $t('estates.latest_deals_title') }}</h4>
        <v-data-table
          v-if="$vuetify.breakpoint.smAndUp"
          fixed-header
          hide-default-footer
          disable-sort
          class="elevation-1"
          :headers="latestDealsHeaders()"
          :items="latestDeals">

          <template v-slot:item.address="{ item }">
            {{ item.address.general_address }}
          </template>

          <template v-slot:item.date="{ item }">
            {{ item.date | moment('MM/YYYY') }}
          </template>

          <template v-slot:item.price="{ item }">
            {{ item.price | currency }}
          </template>
        </v-data-table>
        <template v-else>
          <v-list class="latest-deals-mobile elevation-1">
            <template v-for="(deal,i) in latestDeals">
              <v-subheader :key="`${deal.price}${i}`">
                <v-row justify="space-between" no-gutters>
                  <span>{{deal.date | moment('MM/YYYY')}}</span>
                  <!-- <span>{{deal.price | currencyAbbreviate}}</span> -->
                </v-row>
              </v-subheader>
              <v-list-item :key="`${deal.rooms}${i}`" class="align-start">
                <span>
                  {{deal.address.street}} {{deal.address.number}}
                </span>
                <span>
                  <v-icon color="secondary lighten-5">mdi-circle-small</v-icon>
                  {{deal.rooms}} {{$t('estates.props.rooms')}}
                </span>
                <span>
                  <v-icon color="secondary lighten-5">mdi-circle-small</v-icon>
                  {{deal.size}} {{$t('search.size_units')}}
                </span>
                <span>
                  <v-icon color="secondary lighten-5">mdi-circle-small</v-icon>
                  {{deal.price | currencyAbbreviate}}
                </span>
              </v-list-item>
              <v-divider :key="i" v-if="i < latestDeals.length - 1"/>
            </template>
          </v-list>
        </template>
      </v-container>

      <!-- related estates -->
      <div v-if="relatedEstates.length">
        <v-divider class="margin-tb-35"/>
        <h4 class="font-18 font-600 margin-bottom-10">{{ $t('estates.related_estates') }}</h4>
        <!-- <div v-if="$vuetify.breakpoint.xsOnly">
          <div class="story">
            <v-layout row flex-nowrap class="no-margin">
              <v-flex class="margin-sides-10 story-estate" v-for="(e, i) in relatedEstates" :key="i">
                <EstateBox :estate="e" circle/>
              </v-flex>
            </v-layout>
          </div>
        </div> -->

        <!-- <div v-else> -->
        <div>
          <v-row>
            <v-col cols=12 md=4 v-for="(e, i) in relatedEstates" :key="i">
              <EstateBox :estate="e"/>
            </v-col>
          </v-row>
        </div>
      </div>

      <Spacer height="35px"/>
      <EstateMobileContact :estate="estate" :message="$t('estates.whatsapp_message', {message: estate.title})" v-if="$vuetify.breakpoint.xsOnly"/>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import EstateGallery from '@/app/modules/estates/components/estate-gallery'
import EstatePageSidePane from '@/app/modules/estates/components/estate-side-pane'
import EstateMobileContact from '@/app/modules/estates/components/estate-mobile-contact'
import MainDetails from '@/app/modules/estates/components/main-details'
import EstateDetailsHeader from '@/app/modules/estates/components/estate-details-header'
import EstateSection from '@/app/modules/estates/components/estate-section'
import EstateDocs from '@/app/modules/estates/components/estate-docs'
import EstatesService from '@/app/modules/estates/estates-service'
import EstateMixins from '@/common/mixins/estates/estate-mixins'
import EstateBox from '@/app/modules/estates/components/estate-box'
import LatestDealsService from '@/app/common/services/latest-deals-service'
import QuickContact from '@/common/components/quick-contact'

export default {
  name: 'Estate',

  metaInfo () {
    return {
      title: this.metaTitle,
      meta: [
        {
          property: 'og:title',
          content: this.metaTitle,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.metaOgContent,
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.metaContent,
          vmid: 'description'
        },
        {
          property: 'og:image',
          content: this.metaImage,
          vmid: 'og:image'
        }
      ]
    }
  },

  data () {
    return {
      estate: null,
      relatedEstates: [],
      latestDealsColumns: ['date', 'address', 'size', 'rooms', 'floor', 'build_year', 'price'],
      latestDeals: [],
      isSold: false
    }
  },

  components: {
    EstateGallery,
    MainDetails,
    EstateDetailsHeader,
    EstatePageSidePane,
    EstateMobileContact,
    EstateSection,
    EstateDocs,
    EstateBox,
    QuickContact
  },

  mixins: [EstateMixins],

  watch: {
    estate () {
      this.getRelatedEstates()
    }
  },

  computed: {

    metaTitle () {
      if (!this.estate) return 'Rbns'
      return this.estate.title
    },

    metaImage () {
      let img = require('@/assets/images/logo-full.png')
      if (!this.estate) return img

      // TODO: remove the estate.images logic after all estates were chaged to use assets
      if (this.estate.assets && this.estate.assets.length) {
        const firstImage = this.estate.assets.find((a) => /image/.test(a.asset_type))
        if (firstImage) {
          img = this.$cloudinary.url(`${firstImage.provider_id}.webp`, { width: 150, height: 150, crop: 'fit' })
        } else if (this.estate.images && this.estate.images.length) {
          img = this.$cloudinary.url(this.estate.images[0], { width: 150, height: 150, crop: 'fit' })
        }
      } else if (this.estate.images && this.estate.images.length) {
        img = this.$cloudinary.url(this.estate.images[0], { width: 150, height: 150, crop: 'fit' })
      }

      return img
    },

    metaContent () {
      if (!this.estate) return 'Rbns - Rent Buy & Sale'
      return `${this.estate.title} - ${this.estate.description}`
    },

    metaOgContent () {
      if (!this.estate) return 'Rbns - Rent Buy & Sale'
      const address = this.estate.address.street ? `${this.estate.address.street}, ${this.estate.address.city}` : this.estate.address.city
      return this.$t('meta.estates.og_description', { address })
    },

    video () {
      // return only the first video
      const video = this.estate.assets.find((a) => a.asset_type === 'video')
      return video ? video.provider_id : this.estate.videos ? this.estate.videos[0] : null
    },

    floorPlan () {
      const floorPlan = this.estate.assets.find((a) => a.asset_type === 'floorPlan')
      return floorPlan ? floorPlan.provider_id : null
    },

    vTour () {
      const vTour = this.estate.assets.find((a) => a.asset_type === 'virtualTour')
      return vTour ? vTour.url : null
    }
  },

  methods: {
    setWaterHitterTexts () {
      this.estate.specifications.water_hitter = this.estate.specifications.water_hitter.split(',').reduce((acc, mthd) => {
        acc.push(this.$t(`estates.props.${mthd.trim().toLowerCase()}`))
        return acc
      }, []).toString().replace(/,/g, ', ')
    },

    images () {
      const images = this.estate.assets ? this.estate.assets.filter((a) => /image/.test(a.asset_type)) : null
      if (images.length) {
        return images.map((img) => img.provider_id)
      }
      return this.estate.images
    },

    relatedQuery () {
      return {
        free_text: this.estate.address.city ? this.estate.address.city : this.estate.address.general_address,
        // category: this.estate.types || null,
        deal_type: this.estate.dealType,
        size: {
          min: this.estate.specifications.size - Math.round(parseInt((this.estate.specifications.size * 0.15))),
          max: this.estate.specifications.size + Math.round(parseInt((this.estate.specifications.size * 0.15)))
        },
        rooms: {
          min: this.estate.specifications.rooms - 1,
          max: this.estate.specifications.rooms + 1
        },
        price: {
          min: this.estate.price - Math.round(parseInt((this.estate.price * 0.15))),
          max: this.estate.price + Math.round(parseInt((this.estate.price * 0.15)))
        },
        pagination: {
          page: 1,
          limit: 4
        }
      }
    },

    async getRelatedEstates () {
      const query = this.relatedQuery()
      let { results } = await EstatesService.searchEstates(query)
      results = results.filter((e) => e.id !== this.estate.id)
      if (results.length === 4) {
        results.pop()
      }
      this.relatedEstates = results
    },

    latestDealsHeaders () {
      return Object.keys(this.latestDealsColumns).reduce((arr, i) => {
        arr.push({
          text: this.$t(`latest_deals.${this.latestDealsColumns[i]}`),
          value: this.latestDealsColumns[i]
        })
        return arr
      }, [])
    }
  },

  async mounted () {
    try {
      const res = await EstatesService.getEstate(this.$attrs.slug)
      if (res.slug !== this.$attrs.slug) {
        this.$router.push({ name: 'estate', params: { slug: res.slug } })
      }
      this.estate = res
    } catch (e) {
      if (e.status === 404) {
        this.$router.push({ name: 'notFound' })
        return
      }
    }
    this.isSold = this.estate.status === 'sold'

    this.setWaterHitterTexts()

    if (this.estate.specifications.evacuation_date) {
      this.estate.specifications.evacuation_date = this.$moment(this.estate.specifications.evacuation_date).format('MMMM YYYY')
    }

    this.estate.specifications.age = this.estate.age

    // handle new address format
    if (this.estate.address.location && (!this.estate.address.location.lon || !this.estate.address.location.lat)) {
      if (this.estate.address.location.lng) {
        this.estate.address.location.lon = this.estate.address.location.lng
      } else {
        const location = await this.$maps.getLocationByAddress(this.estate.address.general_address)
        this.estate.address.location = {
          lon: location[0].cords.lng,
          lat: location[0].cords.lat
        }
      }
    }

    this.latestDeals = await LatestDealsService.getDealsByRadius(this.estate.address.location)
    let win360 = document.getElementById('frame360')
    addEventListener('deviceorientation', (e) => {
      win360.contentWindow.postMessage({
        alpha: e.alpha,
        beta: e.beta,
        gamma: e.gamma
      }, '*')
    }, false)
  }
}

</script>

<style lang='scss'>

  .estate-page {

    // .story {
    //   position: relative;
    //   height: 160px;
    //   padding: 30px 0;
    //   overflow: auto;
    //   z-index: 2;

    //   .row {
    //     position: absolute;
    //   }

    //   .story-estate {

    //     .circle {

    //       &:before {
    //         content: '';
    //         position: absolute;
    //         z-index: 1;
    //         width: 100%;
    //         height: 100%;
    //         border-radius: 50%;
    //         // border: 3px solid var(--v-primary-lighten1);
    //         box-shadow: inset 0 0 0 4px var(--v-primary-lighten1) !important;
    //       }

    //       &:after {
    //         content: '';
    //         position: absolute;
    //         z-index: 2;
    //         width: 100%;
    //         height: 100%;
    //         border-radius: 50%;
    //         border-top: 3px solid var(--v-primary-lighten5);
    //         border-left: 3px solid var(--v-primary-lighten5);
    //         animation: spin 2.5s linear infinite;
    //       }
    //     }

    //     &:nth-child(odd) {

    //       .circle:after {
    //         animation-direction: reverse;
    //         animation-duration: 2.2s;
    //       }
    //     }
    //   }
    // }

    .estate-main {

      @include xs-only {
        padding: 35px 15px 0;
      }

      .estate-title-details {
        font-size: $font-xs;
        font-weight: 400;
        line-height: 1.4;

        .v-divider {
          margin: 0 15px;
        }

        .v-icon {
          height: 100%;
        }
      }
    }

    .latest-deals-mobile {
      padding: 0;

      .v-list-item {
        flex-flow: wrap;

        span:first-child {
          text-indent: 9px;
        }
      }
    }

    // &.no-gallery {
    //   padding-top: 80px;
    // }
  }

</style>
