<template>
  <div class="copy-text" v-intersect.once="onIntersect">
    <v-tooltip top allow-overflow color="secondary lighten-2" v-model="popTooltip" v-if="tooltip">
      <template v-slot:activator="{ on }">
        <v-row align="center" no-gutters>
          <v-icon :class="{[iconSize('xs')]: $vuetify.breakpoint.xsOnly, [iconSize('sm')]: $vuetify.breakpoint.smAndUp}" @click="copyText" v-on="on">mdi-content-copy</v-icon>
        </v-row>
      </template>
      <span class="font-2xl font-600" style="color: var(--v-primary-lighten1)">{{tooltip}}</span>
    </v-tooltip>

    <span v-else>
      <v-icon @click="copyText" v-on="on">mdi-content-copy</v-icon>
      <span>{{$t('estates.copy_link')}}</span>
    </span>
    <input type="text" readonly v-model="text" ref="textInput">
  </div>
</template>

<script>
import intersecting from '@/common/mixins/intersecting-mixin'

export default {

  name: 'CopyText',

  props: {
    text: {
      type: String,
      required: true
    },

    tooltip: {
      type: String,
      required: true
    },

    sizes: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      popTooltip: false,
      size: { xs: '2xl', sm: 'xl' }
    }
  },

  mixins: [intersecting],

  watch: {
    isIntersected () {
      setTimeout(() => {
        this.popTooltip = true
      }, 2000)

      setTimeout(() => {
        this.popTooltip = false
      }, 5000)
    }
  },

  methods: {
    copyText () {
      this.$refs.textInput.select()
      this.$refs.textInput.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.trackShare('copy')
    },

    iconSize (size) {
      return `icon-${this.size[size]}`
    }
  },

  created () {
    this.size = Object.assign(this.size, this.sizes)
  }
}
</script>

<style lang="scss" scoped>

  .copy-text {
    position: relative;

    .v-icon {
      transition: all .3s ease-in-out;
      font-size: 1.5rem;

      &:hover {
        transform: scale(1.2);
      }
    }

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      border: none;
      outline: none;
      background: none;

      &::selection {
        background: none;
      }
    }
  }

</style>
