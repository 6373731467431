<template>
  <v-flex xs12 md9>
    <div>
      <Map :addresses="address" height='400px' static/>
      <Spacer height="20px"/>
      <v-row no-gutters align="center">
        <v-btn target="_blank" :href="`https://waze.com/ul?q=${address}`" color="primary" small>
          <v-icon class="icon-md">mdi-waze</v-icon>
          <Spacer width="3px"/>
          {{ $t('estates.navigate_to_estate', { address: '-' }) }}
        </v-btn>
        <Spacer width="10px"/>
        <span class="font-18">{{ address }}</span>
        <Spacer width="10px"/>
      </v-row>
    </div>
  </v-flex>
</template>

<script>
import Map from '@/common/components/map'

export default {

  name: 'EstateMapsection',

  props: ['address'],

  components: {
    Map
  }
}
</script>

<style lang="scss" scoped>
  .v-btn {
    margin-top: 1px;
  }

  .mdi-waze {
    color: $waze-color !important;
  }
</style>
