<template>
  <v-data-table
    hide-default-footer
    disable-sort
    class="elevation-1"
    :headers="headers()"
    :items="rows"/>

</template>

<script>
export default {

  name: 'estateTableSection',

  props: {
    rows: {
      type: Array,
      required: true
    }
  },

  methods: {
    headers () {
      return Object.keys(this.rows[0]).reduce((arr, k) => {
        arr.push({
          text: this.$t(`estates.related_deals.${k}`),
          value: k
        })
        return arr
      }, [])
    }
  }
}
</script>
