<template>
  <v-row class="no-gutters estate-props">

    <v-col cols="12" md="3" v-if="caption">
      <h4 class="font-18 font-600 margin-bottom-10">{{caption}}</h4>
    </v-col>

    <EstateSpecificationsSection v-if="type === 'specifications'" :specifications="value" :attrs="attrs"/>
    <EstateFeaturesSection v-if="type === 'features'" :features="value" :attrs="attrs"/>
    <EstateMapSection v-if="type === 'map'" :address="value" :attrs="attrs"/>
    <EstateTextSection v-if="type === 'text'" :text="value" :attrs="attrs"/>
    <EstateVideoSection v-if="type === 'video'" :video="value" :attrs="attrs"/>
    <EstateIframeSection v-if="type === 'iframe'" :url="value" :attrs="attrs"/>
    <EstateImageSection v-if="type === 'image'" :image="value" :fullWatermark="fullWatermark" :attrs="attrs"/>
    <EstateTableSection v-if="type === 'table'" :rows="value" :attrs="attrs"/>

  </v-row>
</template>

<script type="text/javascript">
import EstateSpecificationsSection from './estate-specifications-section'
import EstateFeaturesSection from './estate-features-section'
import EstateMapSection from './estate-map-section'
import EstateTextSection from './estate-text-section'
import EstateVideoSection from './estate-video-section'
import EstateImageSection from './estate-image-section'
import EstateIframeSection from './estate-iframe-section'
import EstateTableSection from './estate-table-section'

export default {
  name: 'EstateSection',

  components: {
    EstateSpecificationsSection,
    EstateFeaturesSection,
    EstateMapSection,
    EstateTextSection,
    EstateVideoSection,
    EstateImageSection,
    EstateIframeSection,
    EstateTableSection
  },

  props: {
    caption: String,

    type: {
      type: String, // specifications, features, map, text, video, image, table
      required: true
    },

    value: [Object, String], // text, address/location, video, specifications object, features

    showValue: {
      type: Boolean,
      default: true
    },

    attrs: {
      type: Object,
      default: () => {}
    },

    fullWatermark: Boolean
  }
}
</script>

<style lang="scss">
  .estate-props {

    .v-icon {
      vertical-align: text-bottom;
    }

    .props-container {

      > .row {
        margin: 0 -10px;
      }
    }

    .inactive {
      opacity: .4;
    }
  }
</style>
