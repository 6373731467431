<template>
  <v-container fluid class="pdf-viewer">
    <v-carousel hide-delimiters :continuous="false" :height="$vuetify.breakpoint.xs ? 350 : 800">
      <v-carousel-item v-for="i in pages" :key="i"
        class="pdf-viewer-img">

        <div class="text-center">
          <cld-image :publicId="`${file.provider_id}.webp`">
            <cld-transformation :overlay="{fontFamily: 'Arial', fontSize: 200, text: doNotCopy}" opacity="12" angle="45" color="#802700"/>
            <cld-transformation :page="i" height="800" crop="fit"/>
          </cld-image>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {

  name: 'PdfViewer',

  props: {
    file: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      pages: 1
    }
  },

  computed: {
    doNotCopy () {
      return `${encodeURI(this.$t('estates.do_not_copy'))}`
    }
  },

  methods: {
  },

  created () {
    this.pages = this.file.extra_data ? JSON.parse(this.file.extra_data).pages : 1
  }
}
</script>

<style lang="scss" scoped>
  .pdf-viewer {

    &-img img {
      max-width: 100%;
      max-height: 100%;
      pointer-events: none;
    }
  }
</style>
