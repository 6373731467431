<template>
  <v-dialog v-model="dialog"
            max-width="500px"
            close-delay="5"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            transition="dialog-transition"
            @input="dialogStateChange">

    <template v-slot:activator="{ on }">
      <span v-on="on">
        <slot name="activator" v-if="$slots.activator"/>
        <template v-else>
          <v-btn color="secondary" :block="!!activatorText" :icon="!!activatorIcon" :large="$vuetify.breakpoint.xsOnly">
            <span class="font-16" v-if="activatorText">{{ activatorText }}</span>
            <v-icon :color="activatorIconColor" v-if="activatorIcon">{{ activatorIcon }}</v-icon>
          </v-btn>
        </template>
      </span>
    </template>

    <div class="request-showing padding-20">
      <h5 class="font-18 margin-bottom-15">{{ $t('estates.contact_request.title') }}</h5>
      <quick-contact  page="estates"
                      form-name="request_showing"
                      :message="estate.address.general_address"
                      :button-text="$t('estates.contact_request.button_text')"
                      :post="`/estates/contactRequest/${estate.id}`"
                      @success="messageSuccess">

        <template v-slot:hidden-fields>
          <input type="hidden" name="subject" :value="$t('estates.contact_subject')">
        </template>

        <template v-slot:custom-fields>
          <v-textarea name="message" dense outlined auto-grow rows="2" :label="$t('contact.message_label')" :value="$t('estates.whatsapp_message', { message: estate.address.general_address })"/>
        </template>
      </quick-contact>
    </div>

    <v-btn v-if="$vuetify.breakpoint.xsOnly" class="close-btn" color="success" icon @click="dialog = false">
      <v-icon class="icon-3xl" color="secondary lighten-4">mdi-close-circle-outline</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
import QuickContact from '@/common/components/quick-contact'

export default {

  name: 'EstateContactRequestDialog',

  components: {
    QuickContact
  },

  props: {
    estate: {
      type: Object,
      required: true
    },

    activatorText: String,
    activatorIcon: String,

    activatorIconColor: {
      type: String,
      default: 'bright'
    }
  },

  data () {
    return {
      dialog: false
    }
  },

  methods: {
    messageSuccess () {
      this.dialog = false
      this.$emit('success')
    },

    dialogStateChange () {
      if (this.dialog) {
        this.$mixpanel.track('estate_contact_request_open', { slug: this.estate.slug })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-dialog {

    .close-btn {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
</style>
