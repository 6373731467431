import { Api } from '@/common/services/api-service'

const baseUrl = 'latestDeals'

const getLatestDeals = async (params = {}) => {
  try {
    const res = await Api.get(baseUrl, { page: 1, limit: 24 })
    return res
  } catch (e) {
    return e
  }
}

const getDealsByRadius = async (location = null, distance = 400, page = 1, limit = 5) => {
  if (!location) return { error: 'Latest Deals by radius - location must be provided' }

  const res = await Api.post(`${baseUrl}/ByRadius`, { location, distance, pagination: { page, limit } })
  return res
}

export default {
  getLatestDeals,
  getDealsByRadius
}
