<template>
  <v-flex xs12 md9>
    <cld-video :publicId="video" width="100%" controls="true"/>
  </v-flex>
</template>

<script>
export default {

  name: 'estateVideoSection',

  props: ['video']

}
</script>
