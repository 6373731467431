<template>
  <v-flex :class="['estate-iframe-section', {'estate-iframe-section-clicked': clicked}]" xs12 md9 @click="iframeClicked">
    <v-icon color="bright" class="icon-xl">mdi-cursor-default-click-outline</v-icon>
    <iframe :src="url" :id="attrs.id"/>
  </v-flex>
</template>

<script>
export default {

  name: 'estateIframeSection',

  props: ['url', 'attrs', 'animateCursor'],

  data () {
    return {
      clicked: false
    }
  },

  methods: {
    iframeClicked () {
      this.clicked = true
      this.trackOnce('estate_v_tour_clicked')
    }
  }

}
</script>

<style lang="scss" scoped>

  @keyframes tease {
    0% {opacity: 1; transform: translate(315px, 285px);}
    20% {opacity: 1; transform: translate(400px, 270px);}
    40% {opacity: 1; transform: translate(315px, 285px);}
    50% {opacity: 1; transform: translate(230px, 245px);}
    60% {opacity: 1; transform: translate(315px, 255px);}
    80% {opacity: 1; transform: translate(400px, 270px);}
    100% {opacity: 1; transform: translate(315px, 285px);}
  }

  .estate-iframe-section {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }

    .v-icon {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      text-shadow: 0 0px 5px var(--v-secondary-darken4);
      transform: translate(315px, 285px);
      animation-name: tease;
      animation-duration: 12s;
      animation-delay: 8s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in;
    }

    iframe {
      border: 0;
      height: 400px;
      width: 100%;
    }

    &-clicked {

      .v-icon,
      &:before {
        display: none;
      }
    }
  }
</style>
