<template>
  <v-col wrap class="props-container">
    <v-row wrap no-gutters>
      <v-col :cols="12" sm="6" v-for="(value, prop) in specifications" class="margin-bottom-10" v-bind:key="prop" v-show="$vuetify.breakpoint.smAndUp || ($vuetify.breakpoint.mobile && value)">
        <v-layout row class="no-gutters" align-center v-bind:class="{ inactive: value == false }">

          <v-flex xs6 class="font-20">
            <v-icon class="margin-sides-10 icon-lg">{{renderIconName(prop)}}</v-icon>
            <span class="font-300" v-html="$t(`estates.props.${prop}`)" />
            <span>:</span>
          </v-flex>

          <v-flex xs6 :data-hint="specificationsHintsMap[prop]">
            <span v-if="prop == 'block_parcel'" class="font-600 font-20">
              <span v-if="value.block || value.parcel">
                <span>{{value.parcel}}</span>/<span>{{value.block}}</span>
              </span>
              <span v-else>{{$t('estates.props.no_value')}}</span>
            </span>
            <span v-else class="font-600 font-20">{{renderValue(prop)}}</span>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { specificationsIcons } from '@/app/modules/estates/utils/estate-props'

export default {

  name: 'estatePropsSection',

  props: {
    specifications: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      specificationsFiltersMap: {
        size: 'size',
        house_committee: 'currency',
        rate_tax: 'currency'
      },
      specificationsHintsMap: {
        size: this.$t('estates.size_hint')
      }
    }
  },

  methods: {
    renderIconName (prop) {
      return specificationsIcons[prop]
    },

    renderValue (prop) {
      if (!this.specifications[prop]) return this.$t('estates.props.no_value')

      if (this.specificationsFiltersMap[prop]) {
        return this.$options.filters[this.specificationsFiltersMap[prop]](this.specifications[prop])
      } else {
        return this.specifications[prop]
      }
    }
  }
}
</script>

<style lang="scss">
  .props-container {

    [data-hint] {
      position: relative;

      &:after {
        content: attr(data-hint);
        position: absolute;
        bottom: -8px;
        right: 0;
        font-size: $font-xxs;
        font-weight: 600;
        color: var(--v-secondary-lighten3);
      }
    }
  }
</style>
