<template>
  <div class="estate-docs" v-if="(tabu || propertyTax || assessment)">
    <v-divider class="margin-tb-35"/>
    <h4 class="font-18 font-600 margin-bottom-20">{{ $t('estates.estate_info_title') }}</h4>

    <v-expansion-panels>
      <v-expansion-panel v-if="tabu && !isSold">
        <v-expansion-panel-header @click="trackOnce('estate_data_open', { type: 'tabu', slug: estate.slug })">
          <v-row class="padding-tb-10">
            <v-icon color="secondary" class="icon-lg">mdi-file-document-outline</v-icon>
            <div class="padding-sides-10">
              <h5 class="font-18 font-400 padding-bottom-5">
                {{ $t('estates.tabu') }}
              </h5>
              <strong class="font-13">{{ $t('estates.tabu_subtitle') }}</strong>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <cld-image :publicId="tabu" width="100%">
            <cld-transformation :overlay="{fontFamily: 'Arial', fontSize: 85, text: doNotCopy}" opacity="25" angle="25" color="#802700"/>
            <cld-transformation overlay="logos:logo" gravity="south_east" y="10" x="0" width="0.1" opacity="60" flags="relative"/>
          </cld-image>
          <span class="font-13">{{$t('estates.tabu_legal')}}</span>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="propertyTax && !isSold">
        <v-expansion-panel-header @click="trackOnce('estate_data_open', { type: 'tax', slug: estate.slug })">
          <v-row class="padding-tb-10">
            <v-icon color="secondary" class="icon-lg">mdi-file-document-outline</v-icon>
            <div class="padding-sides-10">
              <h5 class="font-18 font-400 padding-bottom-5">
                {{ $t('estates.property_tax') }}
              </h5>
              <strong class="font-13">{{ $t('estates.property_tax_subtitle') }}</strong>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <cld-image :publicId="propertyTax" width="100%">
            <cld-transformation :overlay="{fontFamily: 'Arial', fontSize: 85, text: doNotCopy}" opacity="25" angle="25" color="#802700"/>
            <cld-transformation overlay="logos:logo" gravity="south_east" y="10" x="0" width="0.1" opacity="60" flags="relative"/>
          </cld-image>
          <span class="font-13">{{$t('estates.tabu_legal')}}</span>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="assessment">
        <v-expansion-panel-header @click="trackOnce('estate_data_open', { type: 'assessment', slug: estate.slug })">
          <v-row class="padding-tb-10">
            <v-icon color="secondary" class="icon-lg">mdi-file-document-outline</v-icon>
            <div class="padding-sides-10">
              <h5 class="font-18 font-400 padding-bottom-5">
                {{ $t('estates.assessment') }}
              </h5>
              <strong class="font-13">{{ $t('estates.assessment_subtitle') }}</strong>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <pdf-viewer :file="assessment"/>
          <span class="font-13">{{$t('estates.assessment_legal')}}</span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn class="margin-tb-20" color="primary" @click="openAlbi">{{ $t('estates.albi_more_estate_button') }}</v-btn>
  </div>
</template>

<script>
import pdfViewer from '@/common/components/pdf-viewer'

export default {

  name: 'EstateDocs',

  props: {
    estate: {
      type: Object,
      required: true
    }
  },

  components: {
    pdfViewer
  },

  data () {
    return {

    }
  },

  computed: {
    isSold () {
      return this.estate.status === 'sold'
    },

    doNotCopy () {
      return ` ${encodeURI(this.$t('estates.do_not_copy'))} `
    },

    tabu () {
      const asset = this.estate.assets.find((a) => a.asset_type === 'tabu')
      return asset ? asset.provider_id : null
    },

    propertyTax () {
      const asset = this.estate.assets.find((a) => a.asset_type === 'propertyTax')
      return asset ? asset.provider_id : null
    },

    assessment () {
      return this.estate.assets.find((a) => a.asset_type === 'assessment') || null
    }
  },

  methods: {
    openAlbi () {
      this.$root.$emit('openAlbi', { refering_feature: 'estate_docs' })
    }
  }
}
</script>

<style lang="scss" scoped>

  .estate-docs {

    .v-expansion-panel:before {
      box-shadow: 0 3px 7px 0px var(--v-bright-darken4);
    }
  }

</style>
