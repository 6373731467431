<template>
  <v-flex xs12 md9>
    <cld-image :publicId="image" width="100%">
      <cld-transformation v-if="fullWatermark" :overlay="{fontFamily: 'Arial', fontSize: 200, text: doNotCopy}" opacity="12" angle="45" color="#802700"/>
      <cld-transformation overlay="logos:logo" gravity="south_east" y="10" x="0" width="0.1" opacity="60" flags="relative"/>
    </cld-image>
  </v-flex>
</template>

<script>
export default {

  name: 'estateImageSection',

  props: {
    image: {
      type: String,
      required: true
    },

    fullWatermark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    doNotCopy () {
      return `${encodeURI(this.$t('estates.do_not_copy'))}`
    }
  }
}
</script>

<!-- <style lang="css" scoped>
</style> -->
