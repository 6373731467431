<template>
  <div dir="ltr" @click="open" class="estate-gallery">

    <!-- owl carousel -->
    <Carousel autoplay
              :dots="false"
              :nav="false"
              loop
              :margin="3"
              autoWidth
              :key="showCarouser">

      <div class="video-thumb" v-if="video">
        <v-icon>mdi-play-circle-outline</v-icon>
        <img :src="videoThumb()" :data-index="0" :alt="`${address} video`"/>
      </div>

      <div v-for="(img, i) in imagesUrls" :key="i" :style="{width: `${img.width}px`}">
        <img :src="img.url" :data-index="video ? i+1 : i" :alt="address"/>
      </div>
    </Carousel>

    <!-- map/street view buttons -->
    <div class="map-btns">
      <v-btn small color="primary" @click.stop="openMap">
        {{ $t('estates.map') }}
        <v-icon class="icon-sm">mdi-google-maps</v-icon>
      </v-btn>

      <v-btn small color="primary" @click.stop="openStreetView" v-if="pano">
        {{ $t('estates.street_view') }}
        <v-icon class="icon-sm">mdi-google-street-view</v-icon>
      </v-btn>
    </div>

    <!-- gallery modal -->
    <v-dialog v-model="lightBox" overlay-opacity=".8" content-class="carousel-dialog" :fullscreen="$vuetify.breakpoint.mobile">
      <v-btn fab small color="primary" @click="lightBox = false" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <Map :addresses="address" :locations="[location]" v-if="mapMode" static/>

      <StreetView :location="location" :address="address" v-if="streetMode"/>

      <v-carousel hide-delimiters height="100%" v-model="selectedImage" v-if="galleryMode" @change="carouselSlides" :show-arrows="showArrows()">

        <v-carousel-item v-if="video">
          <div class="video-carousel-content carousel-content">
            <cld-video cloudName="rbns" :publicId="video" controls="true" autoplay ref="vplayer">
              <cld-transformation :height="getHeight()" :width="getWidth()" crop="fit"/>
            </cld-video>
          </div>
        </v-carousel-item>

        <v-carousel-item v-for="(img, i) in images" :key="i">
          <div class="carousel-content">
            <cld-image :publicId="img">
              <cld-transformation :height="getHeight()" :width="getWidth()" crop="fit"/>
              <cld-transformation overlay="logos:logo" gravity="south_east" y="10" x="0" width="0.1" opacity="60" flags="relative"/>
            </cld-image>
            <!-- <img :src="img"/> -->
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import Carousel from 'vue-owl-carousel'
import Map from '@/common/components/map'
import StreetView from '@/common/components/street-view'

export default {
  name: 'EstateGallery',

  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    },

    video: {
      type: String,
      default: ''
    },

    location: {
      type: Object,
      default: null
    },

    address: String
  },

  components: {
    Carousel,
    Map,
    StreetView
  },

  data () {
    return {
      imagesUrls: [],
      galleryMode: false,
      mapMode: false,
      streetMode: false,
      pano: false,
      lightBox: false,
      selectedImage: 0,
      showCarouser: false // print the carousel to the screen only after images size are calculated (on "setCaruselImages")
    }
  },

  watch: {
    lightBox (v) {
      if (!v && this.$refs.vplayer) {
        this.$refs.vplayer.$el.pause()
      }
    }
  },

  methods: {
    getWidth () {
      return Math.round((window.innerWidth * 0.75))
      // return this.$vuetify.breakpoint.xsOnly ? window.innerWidth : Math.round((window.innerWidth * 0.8))
    },

    getHeight () {
      return Math.round((window.innerHeight * 0.75))
      // return this.$vuetify.breakpoint.xsOnly ? '' : Math.round((window.innerWidth * 0.75))
    },

    showArrows () {
      return (this.images.length > 1) || (this.images.length && this.video !== '')
    },

    videoThumb () {
      let url = ''
      if (this.$vuetify.breakpoint.mobile) {
        url = this.imgUrl(`${this.video}.webp`, 300, window.innerWidth, 'video')
      } else {
        url = this.imgUrl(`${this.video}.webp`, 500, '', 'video')
      }
      return url
    },

    open (e) {
      this.selectedImage = parseInt(e.target.dataset.index)
      this.mapMode = false
      this.streetMode = false
      this.galleryMode = true
      setTimeout(() => {
        this.lightBox = true
        this.carouselSlides(this.selectedImage)
      }, 100)
      this.$mixpanel.track('estate_gallery', { media: 'gallery', slug: this.$route.params.slug })
    },

    openMap () {
      this.streetMode = false
      this.galleryMode = false
      this.lightBox = true
      this.mapMode = true
      this.$mixpanel.track('estate_gallery', { media: 'map', slug: this.$route.params.slug })
    },

    openStreetView () {
      this.mapMode = false
      this.galleryMode = false
      this.streetMode = true
      this.lightBox = true
      this.$mixpanel.track('estate_gallery', { media: 'street_view', slug: this.$route.params.slug })
    },

    imgUrl (img, height = '', width = '', resourceType = 'image') {
      const url = this.$cloudinary.url(img, {
        resource_type: resourceType,
        transformation: [{
          crop: this.$vuetify.breakpoint.mobile ? 'fill' : 'fit',
          height,
          width
        },
        {
          overlay: 'logos:logo',
          width: 0.1,
          gravity: 'south_east',
          x: 0,
          y: 10,
          opacity: 60,
          flags: 'relative'
        }]
      })
      return url
    },

    carouselSlides (v) {
      if (this.$refs.vplayer) {
        if (v === 0) {
          this.$refs.vplayer.$el.play()
        } else {
          this.$refs.vplayer.$el.pause()
        }
      }
    },

    async checkStreetView () {
      let cords = this.location ? this.location.cords : null
      if (!cords) {
        const location = await this.$maps.getLocationByAddress(this.address)
        cords = location[0].cords
      }

      if (!cords) {
        console.error(`could no find location for address - ${this.address}`)
        return false
      }

      const sv = await this.$maps.StreetViewService()
      sv.getPanorama({ location: cords, radius: 500 }, (data, stat) => {
        if (stat === 'OK') {
          this.pano = true
        }
      })
    },

    setCaruselImages () {
      this.images.forEach((img, i) => {
        let index = i
        let url = ''
        if (this.$vuetify.breakpoint.mobile) {
          url = this.imgUrl(img, 300, window.innerWidth)
        } else {
          url = this.imgUrl(img, 500)
        }

        this.imagesUrls.push({ url })
        let newImg = new Image()

        newImg.onload = () => {
          this.imagesUrls[index].width = this.$vuetify.breakpoint.mobile ? window.innerWidth : newImg.naturalWidth > 1000 ? 1000 : newImg.naturalWidth
          if (this.imagesUrls.length === this.images.length) {
            this.showCarouser = true
          }
        }
        newImg.src = url
      })
    }
  },

  created () {
    this.checkStreetView()
    this.setCaruselImages()
  }
}

</script>

<style lang='scss'>

  .estate-gallery {
    position: relative;
    height: 500px;
    overflow: hidden;

    .video-thumb {
      position: relative;
      cursor: pointer;

      .v-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        font-size: $font-4xl;
        z-index: 9;
        text-shadow: 0px 0px 2px $text_light;
      }
    }

    .owl-carousel {

      .owl-item {

        img {
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
      }
    }

    .map-btns {
      position: absolute;
      bottom: 15px;
      left: 15px;
      z-index: 1;

      .v-btn {
        margin: 0 2px;
      }
    }

    .mobile & {
      height: 300px;
    }
  }

</style>
