<template>
  <v-row class="street-view-wrapper" no-gutters>
    <v-flex class="street-view" ref="street_view"/>
  </v-row>
</template>

<script type="text/javascript">

export default {
  name: 'StreetView',

  props: {
    address: String,
    location: Object
  },

  data () {
    return {
      streetView: {}
    }
  },

  methods: {

    async geocoder () {
      var geocoder = await this.$maps.Geocoder()
      geocoder.geocode({ 'address': this.address }, (results, status) => {
        if (status === 'OK') {
          this.loadStreetView(results[0].geometry.location)
        }
      })
    },

    async loadStreetView (location = null) {
      if (!location) return
      const sv = await this.$maps.StreetViewService()
      sv.getPanorama({ location: location, radius: 500 }, (data, status) => {
        if (status === 'OK') {
          this.streetView.setPano(data.location.pano)
        }
      })
    }
  },

  async mounted () {
    this.streetView = await this.$maps.StreetViewPanorama(this.$refs.street_view)
    if (this.location) {
      this.loadStreetView(this.location.cords)
    } else if (this.address) {
      this.geocoder()
    }
  }
}

</script>

<style lang="scss">

  .street-view-wrapper {
    position: relative;
    min-height: 400px;
    height: 100%;
    border: 1px solid var(--v-bright-darken1);

  }

</style>
