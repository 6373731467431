<template>
  <v-flex xs12 md9>
    <div class="font-20">
      <vue-markdown>{{ text }}</vue-markdown>
    </div>
  </v-flex>
</template>

<script>
export default {

  name: 'estateTextSection',

  props: ['text']

}
</script>
