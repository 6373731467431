<template>
  <div>
    <v-container class="estate-main-details">
      <v-row no-gutters>
        <v-col class="estate-price" cols="12" sm="" tag="h5">
          {{estate.price | currency}}
          <div class="font-13 margin-tb-5" v-if="estate.specifications.size">{{ $t('estates.size_per_unit', { size: sizePerUnit })}}</div>
        </v-col>

        <v-col class="details margin-tb-15" justify-center>
          <v-row>
            <MainDetail icon="mdi-floor-plan" :caption="$t('estates.property_size')" :text="sizeText"/>
            <v-divider vertical/>
            <MainDetail icon="mdi-bed-outline" :caption="$t('estates.props.rooms')" :text="estate.specifications.rooms || $t('estates.props.no_value')"/>
            <v-divider vertical/>
            <MainDetail icon="mdi-parking" :caption="$t('estates.props.parking')" :text="estate.specifications.parkings || $t('estates.props.no_value')"/>
          </v-row>
        </v-col>
      </v-row>
      <h2 class="font-25 address">
        {{fullAddress}}
      </h2>
    </v-container>
    <v-divider/>
  </div>
</template>

<script type="text/javascript">
import MainDetail from '@/app/modules/estates/components/main-detail'
import EstateMixins from '@/common/mixins/estates/estate-mixins'

export default {
  name: 'MainDetails',

  components: {
    MainDetail
  },

  props: {
    estate: {
      type: Object,
      required: true
    }
  },

  mixins: [EstateMixins],

  computed: {
    sizePerUnit () {
      return this.$options.filters.currency(this.estate.price / this.estate.specifications.size)
    },

    sizeText () {
      return this.estate.specifications.size ? this.$options.filters.size(this.estate.specifications.size) : this.$t('estates.props.no_value')
    }
  }
}
</script>

<style lang="scss">

  .estate-main-details {
    padding: 26px 0 15px;

    .address {
      margin-top: 5px;
    }

    .v-divider:not(:first-child) {
      margin-left: 10px;

      &:lang(he) {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .estate-price {
      font-size: $font-2xl;
      font-weight: 600;
      color: var(--v-accent-darken2);
      line-height: 1.1;

      div {
        font-size: $font-sm;
        color: var(--v-secondary-lighten4);
      }
    }

    .details {
      margin-right: -10px; // reversing the first icon margin

      &:lang(he) {
        margin-left: -10px;
      }
    }

    @include xs-only {
      padding: 0 0 26px;

      .details {
        padding: 10px 0;
      }
    }
  }

</style>
