<template>
  <div class="d-flex">
    <v-icon class="icon-4xl margin-sides-10" color="secondary lighten-2">{{icon}}</v-icon>
    <v-layout column class="font-15">
      <span class="font-400">{{caption}}</span>
      <span class="font-600">{{text}}</span>
    </v-layout>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'MainDetailComponent',
  props: {
    icon: String,
    caption: String,
    text: [String, Number]
  }
}
</script>
